import { match } from 'kitchen/router'
import * as Segment from './segment'

export const AUTH = match(Segment.AUTH, Segment.ALL)
export const ACTIVITIES = match(Segment.ACTIVITIES)
export const ALL = match(Segment.ALL)
export const COMPANIES = match(Segment.COMPANIES, Segment.ALL)
export const COMPANY_ID = match(Segment.COMPANY_ID, Segment.ALL)
export const COMPLIANCE_ALERTS = match(Segment.COMPLIANCE_ALERTS, Segment.ALL)
export const COMPLIANCE_ALERT_ID = match(Segment.COMPLIANCE_ALERT_ID, Segment.ALL)
export const PAYOUTS = match(Segment.PAYOUTS, Segment.ALL)
export const PAYRUNS = match(Segment.PAYRUNS, Segment.ALL)
export const TOPUPS = match(Segment.TOPUPS, Segment.ALL)
export const TOPUP_ID = match(Segment.TOPUP_ID, Segment.ALL)
export const PAYRUN_ID = match(Segment.PAYRUN_ID, Segment.ALL)
export const TOOLS = match(Segment.TOOLS)
export const SETTINGS = match(Segment.SETTINGS, Segment.ALL)
export const AGENTS = match(Segment.AGENTS, Segment.ALL)
