import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { CacheKey } from 'kitchen/constants'
import { usePublicFetch } from 'kitchen/hooks/use-public-fetch'
import type { FetchError, UseMutationOptions, UseQueryOptions } from 'kitchen/types'
import { immutableRequestOptions } from 'kitchen/utils/fetch'
import { getAllAgents, getCurrentAgent, toggleAgentRole } from '../requests/bo-agents'
import type { AgentResponse, AgentToggleRolePayload } from '../types/bo-agents'

export function useCurrentAgent(options?: UseQueryOptions<AgentResponse>) {
  const fetch = usePublicFetch()

  return useQuery<AgentResponse, FetchError>(
    [CacheKey.CURRENT_AGENT],
    ({ signal }) => getCurrentAgent(fetch, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export function useAgents(options?: UseQueryOptions<AgentResponse[]>) {
  const fetch = usePublicFetch()

  return useQuery<AgentResponse[], FetchError>(
    [CacheKey.ALL_AGENTS],
    ({ signal }) => getAllAgents(fetch, signal),
    {
      ...immutableRequestOptions,
      ...options,
    }
  )
}

export interface AgentToggleRoleOptions
  extends UseMutationOptions<AgentToggleRolePayload, void> {}

export function useToggleRole(options?: AgentToggleRoleOptions) {
  const fetch = usePublicFetch()
  const queryClient = useQueryClient()

  return useMutation((payload) => toggleAgentRole(fetch, payload), {
    ...options,
    async onSuccess(data, payload, context) {
      await queryClient.invalidateQueries([CacheKey.ALL_AGENTS])
      return options?.onSuccess?.(data, payload, context)
    },
  })
}
