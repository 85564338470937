import { Suspense } from 'react'
import { BOProviders } from './bo-providers'
import { BORoutes } from './bo-routes'
import { LoadingStatus } from './components/loading-status'
import { globalStyles } from './global-styles'

export function BackofficeApp() {
  globalStyles()
  return (
    <BOProviders>
      <Suspense fallback={<LoadingStatus layout="page" />}>
        <BORoutes />
      </Suspense>
    </BOProviders>
  )
}
