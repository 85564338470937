import { QueryClient, QueryClientProvider, QueryCache } from '@tanstack/react-query'
import { CacheKey, Locale } from 'kitchen/constants'
import { IntlProvider } from 'kitchen/context/intl'
import { BrowserRouter as Router } from 'kitchen/router'
import { ExtendedResponseError } from 'kitchen/utils/error'
import { Tooltip, Toast } from 'salad/components'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      const [queryKey] = query.queryKey
      if (
        error instanceof ExtendedResponseError &&
        error.response.status === 401 &&
        queryKey !== CacheKey.CURRENT_AGENT
      ) {
        // handled in <RequireAuth>
        queryClient.invalidateQueries([CacheKey.CURRENT_AGENT])
      }
    },
  }),
})

interface ProvidersProps {
  children?: React.ReactNode
}

export const BOProviders = ({ children }: ProvidersProps) => {
  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={Locale.DEFAULT}>
          <Toast.Provider>
            <Tooltip.Provider>{children}</Tooltip.Provider>
          </Toast.Provider>
        </IntlProvider>
      </QueryClientProvider>
    </Router>
  )
}
