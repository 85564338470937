import dayjsLib from 'dayjs'
import type {
  Dayjs,
  ConfigType as AnyDateFormat,
  ManipulateType,
  UnitTypeLong,
} from 'dayjs'
import 'dayjs/locale/en-gb'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import isSameOrAfterPlugin from 'dayjs/plugin/isSameOrAfter'
import isSameOrBeforePlugin from 'dayjs/plugin/isSameOrBefore'
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import weekdayPlugin from 'dayjs/plugin/weekday'
import weekOfYearPlugin from 'dayjs/plugin/weekOfYear'

// Dayjs

export type { Dayjs }
export type { AnyDateFormat }

const initDayJs = () => {
  // NB: setting 'en' locale sets DayJs into American English locale.
  // That would make calendar week start from Sunday. (works fine though)
  dayjsLib.locale('en-gb')
  dayjsLib.extend(weekdayPlugin)
  dayjsLib.extend(weekOfYearPlugin)
  dayjsLib.extend(isBetweenPlugin)
  dayjsLib.extend(localizedFormatPlugin)
  dayjsLib.extend(isSameOrAfterPlugin)
  dayjsLib.extend(isSameOrBeforePlugin)
  dayjsLib.extend(customParseFormat)
  return dayjsLib
}

const dayjs = initDayJs()

function getCalendarMonth(date: Dayjs) {
  return {
    year: date.get('year'),
    month: date.get('month'),
  }
}

function getNextMonthDate(year: number, month: number, day = 0) {
  return setDate({ year, month, day }).add(1, 'month')
}

function getPreviousMonthDate(year: number, month: number, day = 0) {
  return setDate({ year, month, day }).subtract(1, 'month')
}

export function getPreviousCalendarMonth(year: number, month: number, day = 0) {
  const prevMonth = getPreviousMonthDate(year, month, day)
  return getCalendarMonth(prevMonth)
}

export function getNextCalendarMonth(year: number, month: number, day = 0) {
  const nextMonth = getNextMonthDate(year, month, day)
  return getCalendarMonth(nextMonth)
}

export function getNumberOfDaysInMonth(year: number, month: number) {
  return setDate({ year, month }).daysInMonth()
}

/**
 * if no date specified, returns current year
 */
export function getYear(date?: AnyDateFormat) {
  return dayjs(date).get('year')
}

/**
 * starts from 0.
 * if no date specified, returns current month.
 */
export function getMonth(date?: AnyDateFormat) {
  return dayjs(date).get('month')
}

export function isSame(
  date1?: AnyDateFormat,
  date2?: AnyDateFormat,
  unit: UnitTypeLong = 'millisecond'
) {
  if (!date1 || !date2) {
    return false
  }
  return dayjs(date1).isSame(date2, unit)
}

export function isSameDay(date1?: AnyDateFormat, date2?: AnyDateFormat) {
  return isSame(date1, date2, 'day')
}

export function isSameMonth(date1?: AnyDateFormat, date2?: AnyDateFormat) {
  return isSame(date1, date2, 'month')
}

export function isBetween(
  inputDate?: AnyDateFormat,
  startDate?: AnyDateFormat,
  endDate?: AnyDateFormat,
  excludeEdges = false
) {
  if (!inputDate || !startDate || !endDate) {
    return false
  }
  return dayjs(inputDate).isBetween(startDate, endDate, 'day', excludeEdges ? '()' : '[]')
}

export function isSameOrBefore(
  date1?: AnyDateFormat,
  date2?: AnyDateFormat,
  unit: UnitTypeLong = 'millisecond'
) {
  if (!date1 || !date2) {
    return false
  }
  return dayjs(date1).isSameOrBefore(date2, unit)
}

export function isSameOrAfter(
  date1?: AnyDateFormat,
  date2?: AnyDateFormat,
  unit: UnitTypeLong = 'millisecond'
) {
  if (!date1 || !date2) {
    return false
  }
  return dayjs(date1).isSameOrAfter(date2, unit)
}

export function isAfter(
  date1?: AnyDateFormat,
  date2?: AnyDateFormat,
  unit: UnitTypeLong = 'millisecond'
) {
  if (!date1 || !date2) {
    return false
  }
  return dayjs(date1).isAfter(date2, unit)
}

/**
 * starts from 0
 */
export function getMonthName(month: number) {
  return dayjs().month(month).format('MMMM')
}

/**
 * month and day start from 0
 */
export function setDate({
  year,
  month,
  day = 0,
}: {
  year: number
  month: number
  day?: number
}) {
  return dayjs()
    .set('year', year)
    .set('month', month)
    .set('date', day + 1) // starting all numbers from 0
}

export function getWeekday(date: AnyDateFormat) {
  return dayjs(date).weekday()
}

export const yesterday = () => {
  return today().subtract(1, 'day')
}

export const today = () => {
  return dayjs()
}

export function isToday(date: AnyDateFormat) {
  return isSameDay(today(), date)
}

export function subtract(date: AnyDateFormat, number: number, unit: ManipulateType) {
  return dayjs(date).subtract(number, unit)
}

export function isWeekend(date: AnyDateFormat) {
  return [5, 6].includes(dayjs(date).weekday())
}

export function getNextBusinessDay(sinceDate: AnyDateFormat): Dayjs {
  const initialDate = dayjs(sinceDate)
  const nextDate = initialDate.add(1, 'day')
  if (isWeekend(nextDate)) {
    return getNextBusinessDay(nextDate)
  }
  return nextDate
}

export function thirdNextBusinessDayAfterToday(): Dayjs {
  return getNextBusinessDay(getNextBusinessDay(getNextBusinessDay(today()))).startOf(
    'day'
  )
}

export function secondNextBusinessDayAfterToday(): Dayjs {
  return getNextBusinessDay(getNextBusinessDay(today())).startOf('day')
}

/**
 *
 * @param input user input in provided format
 * @param inputFormat defaults to dd/mm/yyyy
 * @returns date in ISO format or null if not valid
 */
export const inputToISOString = (input: string, inputFormat = 'DD/MM/YYYY') => {
  const date = dayjs(input, inputFormat, true)
  if (!date.isValid()) {
    return null
  }
  return date.format('YYYY-MM-DD')
}

/**
 *
 * @returns true if input is of shape 2024-01-31
 */
export const checkIsISODateString = (input: string | undefined): input is string =>
  input !== undefined && /^\d{4}\-\d{2}\-\d{2}$/.test(input)
