export const ACCOUNTING_ITEMS = 'accounting-items'
/* eslint "sort-exports/sort-exports": "error" */
export const ACCOUNTS = 'accounts'
export const ACTIVITIES = 'activities'
export const ADDRESS_SUGGESTIONS = 'address-suggestions'
export const ADVANCED_COMPANY_SETTINGS = 'advanced-company-settings'
export const ALERT_SUBJECT_TYPES = 'alert-subject-types'
export const ALL_AGENTS = 'all-agents'
export const APP_NOTIFICATIONS = 'app-notifications'
export const AUDIT_LOG = 'audit-log'
export const AUTH = 'auth'
export const AUTH_ENROLLMENT_INFO = 'auth-enrollment-info'
export const BANK_FEED = 'bank-feed'
export const BANK_FEED_SUGGESTIONS = 'bank-feed-suggestions'
export const BANK_FEED_SYNC_STATUS = 'bank-feed-sync-status'
export const BANK_FEED_TRANSACTION = 'bank-feed-transaction'
export const BANK_FEED_TRANSACTION_COMMENTS = 'bank-feed-transaction-comments'
export const BENEFICIARIES = 'beneficiaries'
export const BENEFICIARIES_LOG = 'beneficiaries-log'
export const BENEFICIARY = 'beneficiary'
export const BENEFICIARY_COUNTRIES = 'beneficiary-countries'
export const BENEFICIARY_FIELDS = 'beneficiary-fields'
export const BILLING = 'billing'
export const BOOKKEEPING_ACCOUNTS = 'bookkeeping-accounts'
export const BOOKKEEPING_COLUMN_SETTINGS = 'bookkeeping-column-settings'
export const CALLBACK = 'callback'
export const CARDS = 'cards'
export const COMPANIES = 'companies'
export const COMPANY_COMMENTS = 'company-comments'
export const COMPANY_DETAILS = 'company-details'
export const COMPANY_KYB = 'company-kyb'
export const COMPANY_KYB_STATUS = 'company-kyb-status'
export const COMPANY_KYC = 'company-kyc'
export const COMPANY_NAME_SEARCH = 'company-name-search'
export const COMPANY_NUMBER_SEARCH = 'company-number-search'
export const COMPANY_SEARCH = 'company-search'
export const COMPANY_SECURITY = 'company-security'
export const COMPANY_SETTINGS = 'company-settings'
export const COMPANY_STATUS = 'company-status'
export const COMPANY_SYNC = 'company-sync'
export const COMPANY_TRACKING_CATEGORIES = 'company-tracking-categories'
export const COMPANY_USERS = 'company-users'
export const COMPANY_WORKFLOWS = 'company-workflows'
export const COMPLIANCE_ALERT = 'compliance-alert'
export const COMPLIANCE_ALERTS = 'compliance-alerts'
export const COMPLIANCE_ALERTS_COMMENTS = 'compliance-alerts-comments'
export const COMPLIANCE_ALERTS_RULES = 'compliance-alerts-rules'
export const COMPLIANCE_BENEFICIARY_CHECK = 'compliance-alerts-beneficiary-check'
export const CONTACT = 'contact'
export const CONTACTS = 'contacts'
export const CONTACT_RULES = 'contact-rules'
export const CURRENT_AGENT = 'current-agent'
export const CURRENT_USER = 'current-user'
export const DETAILS = 'details'
export const DOCUMENTS = 'documents'
export const DOCUMENTS_BATCH_ACTIONS = 'documents-batch-actions'
export const DOCUMENTS_BATCH_ACTION_DETAILS = 'documents-batch-action-details'
export const DOCUMENT_COMMENTS = 'document-comments'
export const DOCUMENT_CURRENCIES = 'document-currencies'
export const DOCUMENT_DETAILS = 'document-details'
export const DOCUMENT_EMAIL_CHAIN = 'document-email-chain'
export const DOCUMENT_EXTERNAL_DUPLICATES = 'document-external-duplicates'
export const EXPENSE_CONTACTS = 'expense-contacts'
export const FAILED_TOPUPS = 'failed-topups'
export const FEATURES = 'features'
export const FINANCIAL = 'financial'
export const FINANCIAL_ACCOUNTS = 'financial-accounts'
export const FORCE_2FA_DETAILS = 'force-2fa-details'
export const FUNDING_ACCOUNT = 'funding-account'
export const FX_LIMITS = 'fx-limits'
export const FX_RATES = 'fx-rates'
export const INVOICES = 'invoices'
export const INVOICES_LINE_ITEMS = 'invoices-line-items'
export const INVOICE_ATTACHMENTS = 'invoice-attachments'
export const INVOICE_ATTACHMENT_BLOB = 'invoice-attachment-blob'
export const INVOICE_ATTACHMENT_URL = 'invoice-attachment-url'
export const PAID_PAYMENTS = 'paid-payments'
export const PAYMENTS_TOPUP_CURRENCIES = 'payments-topup-currencies'
export const PAYMENT_METHOD = 'payment-method'
export const PAYMENT_PROGRESS = 'payment-progress'
export const PAYOUTS = 'payouts'
export const PAYRUN = 'payrun'
export const PAYRUNS = 'payruns'
export const PAYRUNS_TAGS = 'payrun-tags'
export const PAYRUN_COMMENTS = 'payrun-comments'
export const PAYRUN_ESTIMATES = 'payrun-estimates'
export const PAYRUN_FUNDING_ATTEMPTS = 'payrun-funding-attempts'
export const PAYRUN_PAYMENTS = 'payrun-payments'
export const PAYRUN_PENDING_COUNT = 'payrun-pending-count'
export const PAYRUN_SEARCH = 'payrun-search'
export const PAYRUN_UNMATCHED_TRANSACTIONS = 'payrun-unmatched-transactions'
export const PENDING_PAYMENTS = 'pending-payments'
export const PENDING_PAYRUNS = 'pending-payruns'
export const PEOPLE_KYC = 'people-kyc'
export const PRACTICE = 'practice'
export const PRICING_PLAN = 'pricing-plan'
export const PRICING_PLANS_LIST = 'pricing-plans-list'
export const REMITTANCE_ADVICES = 'remittance-advices'
export const STATS = 'pending-payruns-stats'
export const SUBSCRIBE = 'subscribe'
export const SUBSCRIPTIONS = 'subscriptions'
export const SUGGESTIONS = 'pending-payruns-suggestions'
export const TAX_TYPES = 'tax-types'
export const TOOLS = 'tools'
export const TOPUP_ACCOUNTS = 'topup-accounts'
export const TOPUP_OPTIONS = 'topup-options'
export const USAGE = 'usage'
export const USERS = 'users'
export const USER_DEPENDENCIES = 'user-dependencies'
export const USER_SEARCH = 'user-search'
export const USER_SETTINGS = 'user-settings'
