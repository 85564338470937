import type { GenericComponent } from 'kitchen/types'
import { ImpossibleError } from 'kitchen/utils/error'
import { Status, Surface } from 'salad/components'
import * as Icons from 'salad/icons'
import { StatusLayout } from 'salad/layouts'

export interface ErrorStatusProps {
  layout: 'inline' | 'dialog' | 'page' | 'public-page'
  children?: React.ReactNode
}

function ErrorStatus({ layout, children }: ErrorStatusProps) {
  const content = (
    <Status.Root variant={layout === 'dialog' ? 'dialog' : undefined}>
      {children ?? (
        <>
          <ErrorStatusIcon />
          <ErrorStatusTitle />
        </>
      )}
    </Status.Root>
  )

  switch (layout) {
    case 'page':
    case 'public-page':
      return (
        <StatusLayout.Root>
          {layout === 'public-page' && <StatusLayout.Logo variant="small" />}
          <StatusLayout.Content>{content}</StatusLayout.Content>
        </StatusLayout.Root>
      )
    case 'inline':
      return content
    case 'dialog':
      return (
        <Surface variant="flat" p={32}>
          {content}
        </Surface>
      )
    default:
      throw new ImpossibleError('Unhandled layout variant', layout)
  }
}

export interface ErrorStatusIconProps extends Status.StatusIconProps {}

function ErrorStatusIcon({ children, ...rest }: ErrorStatusIconProps) {
  return <Status.Icon {...rest}>{children ?? <Icons.S64.Failure />}</Status.Icon>
}

export interface ErrorStatusTitleProps extends Status.StatusTitleProps {}

function ErrorStatusTitle({ children, ...rest }: ErrorStatusTitleProps) {
  return <Status.Title {...rest}>{children ?? 'Something went wrong'}</Status.Title>
}

export interface ErrorStatusDescriptionProps extends Status.StatusDescriptionProps {}

function ErrorStatusDescription({ children, ...rest }: ErrorStatusDescriptionProps) {
  return (
    <Status.Description {...rest}>{children ?? 'Please try again'}</Status.Description>
  )
}

const ErrorStatusAction: GenericComponent<Status.StatusActionProps> = ({
  children,
  ...rest
}: Status.StatusActionProps) => (
  <Status.Action {...rest}>{children ?? 'Try again'}</Status.Action>
)

export {
  ErrorStatus as Root,
  ErrorStatusIcon as Icon,
  ErrorStatusTitle as Title,
  ErrorStatusDescription as Description,
  ErrorStatusAction as Action,
}
