import { useNavigate } from 'kitchen/router'
import { Box } from 'salad/primitives'
import { keyframes } from 'salad/stitches'
import { ErrorStatus } from '../error-status'
import tumbleweed from './tumbleweed.png'

const rotate = keyframes({
  to: {
    transform: 'rotate(360deg)',
  },
})

const bounce = keyframes({
  from: {
    transform: 'translate3d(0, 0, 0)',
  },
  to: {
    transform: 'translate3d(0, 20px, 0)',
  },
})

export function NotFound() {
  const navigate = useNavigate()
  return (
    <ErrorStatus.Root layout="page">
      <ErrorStatus.Icon
        css={{
          animation: `${bounce} 0.5s cubic-bezier(.5, 0.05, 1, .5) infinite alternate`,
          img: {
            height: '120px',
          },
        }}
      >
        <Box
          as="img"
          src={tumbleweed}
          alt="not found"
          css={{
            animation: `${rotate} 5s linear infinite`,
          }}
        />
      </ErrorStatus.Icon>
      <ErrorStatus.Title>Page not found</ErrorStatus.Title>
      <ErrorStatus.Action onClick={() => navigate(-1)}>Get back</ErrorStatus.Action>
    </ErrorStatus.Root>
  )
}
