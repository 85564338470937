import { useCurrentAgent } from 'api/hooks/bo-agents'
import { Navigate } from 'kitchen/router'
import { ExtendedResponseError } from 'kitchen/utils/error'
import { ErrorStatus } from '../../components/error-status'
import { LoadingStatus } from '../../components/loading-status'
import { Path } from '../../constants'

interface RequireAuthProps {
  children?: React.ReactNode
}

/**
 * Backoffice auth is powered by cookies. So it doesn't matter
 * which request to fire for checking if session is active.
 * We call /me as its data is important for the whole BO app.
 */

export const RequireAuth = ({ children }: RequireAuthProps) => {
  const currentAgent = useCurrentAgent()

  switch (currentAgent.status) {
    case 'loading':
      return <LoadingStatus layout="page" delay={500} />
    case 'error':
      if (
        currentAgent.error instanceof ExtendedResponseError &&
        currentAgent.error.response.status === 401
      ) {
        return <Navigate to={Path.AUTH} />
      }
      return (
        <ErrorStatus.Root layout="page">
          <ErrorStatus.Icon />
          <ErrorStatus.Title />
          <ErrorStatus.Description>Failed to load agent data 😵</ErrorStatus.Description>
        </ErrorStatus.Root>
      )
    case 'success':
      return <>{children}</>
  }
}
