import 'salad/static/fonts.css'
import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BackofficeApp } from './bo-app'

const element = document.getElementById('root')

if (element !== null) {
  const root = createRoot(element)

  root.render(
    <StrictMode>
      <BackofficeApp />
    </StrictMode>
  )
}
